import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import TextCard from "../../components/textCard";
import { ChevronRight, Clock, User } from "../../assets/icons/icons";
import Button from "../../components/buttons";
import { Link } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { withAlert } from "react-alert";
import homeStore from "../../stores/adminStores/homeStore";
import CommonStore from "../../stores/superAdminStores/commonStore";
import Loader from "react-loader-spinner";

import "./styles.scss";
import moment from "moment";
const HomePage = ({ alert }) => {
	const [selectionRange, setSelectionRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: "selection",
	});
	const [showDateRange, setShowDateRange] = useState(true);
	const [showDateRangeFilters, setShowDateRangeFilters] = useState(false);
	const handleSelect = (ranges) => {
		setSelectionRange(ranges.selection);
		setFilterCount(filterCount + 1);
	};

	const [logistics, setLogistics] = useState(null);
	const [cokitchens, setCokitchens] = useState([]);
	const [todayTrips, setTodayTrips] = useState([]);
	const [yesterdayTrips, setYesterdayTrips] = useState([]);
	const [allTrips, setAllTrips] = useState([]);
	const [fleets, setFleets] = useState([]);
	const [loading, setLoading] = useState(false);
	const [showAllTrips, setShowAllTrips] = useState(false);
	const [showTodayTrips, setShowTodayTrips] = useState(true);
	const [showYesterdayTrips, setShowYesterdayTrips] = useState(true);
	const [refreshCount, setRefreshCount] = useState(0);
	const [bikesOnline, setBikesOnline] = useState([]);
	const [bikesOffline, setBikesOffline] = useState([]);
	const [filterCount, setFilterCount] = useState(0);

	// load data from store
	useEffect(async () => {
		if (!homeStore.dataCached) {
			await loadData();
		}
		setLoading(false);
		setLogistics(homeStore.logistics);
		sortBikers();
		sortTrips();
		setCokitchens(CommonStore.cokitchens);
	}, []);

	useEffect(async () => {
		if (refreshCount) {
			await loadData();
		}
	}, [refreshCount]);

	// Filter stats by date
	useEffect(() => {
		if (filterCount > 1) {
			let i, j;

			let tripsCopy = homeStore.logistics && homeStore.logistics.trips;

			let currentTrips = [];

			for (i = 0; i < tripsCopy.length; i++) {
				if (
					moment(
						moment(tripsCopy[i].created_at).format("YYYY-MM-DD")
					).isBetween(
						moment(moment(selectionRange.startDate).subtract(1, "d")).format(
							"YYYY-MM-DD"
						),
						moment(moment(selectionRange.endDate).add(1, "d")).format(
							"YYYY-MM-DD"
						)
					)
				) {
					currentTrips.push(tripsCopy[i]);
				}
			}

			setLogistics({
				...logistics,

				trips: currentTrips,
			});
		} else {
			setLogistics(homeStore.logistics);

			setSelectionRange({
				startDate: new Date(),
				endDate: new Date(),
				key: "selection",
			});
		}
	}, [filterCount]);

	// load data from store

	const loadData = async () => {
		setLoading(true);
		await Promise.all([
			homeStore.getLogistics(alert, true, moment),
			CommonStore.getCokitchens(alert),
		]);

		setLogistics(homeStore.logistics);
		sortBikers();
		sortTrips();
		setCokitchens(CommonStore.cokitchens);

		setLoading(false);
	};
	const sortBikers = () => {
		let online = [];
		let offLine = [];
		let allRiders =
			(homeStore && homeStore.logistics && homeStore.logistics.fleets) || [];
		let i;
		for (i = 0; i < allRiders.length; i++) {
			if (allRiders[i].online) {
				online.push(allRiders[i]);
			} else {
				offLine.push(allRiders[i]);
			}
		}
		setFleets(allRiders);
		setBikesOnline(online);
		setBikesOffline(offLine);
	};

	// Sort trips into today, yesterday and all time
	const sortTrips = () => {
		let todTrips = [];
		let yesTrips = [];
		let today = moment().format("DD-MM-YYYY");
		let yesterday = moment().add(-1, "days").format("DD-MM-YYYY");
		let allTrips =
			(homeStore && homeStore.logistics && homeStore.logistics.trips) || [];
		let i;
		for (i = 0; i < allTrips.length; i++) {
			if (moment(allTrips[i].created_at).format("DD-MM-YYYY") === today) {
				todTrips.push(allTrips[i]);
			} else if (
				moment(allTrips[i].created_at).format("DD-MM-YYYY") === yesterday
			) {
				yesTrips.push(allTrips[i]);
			}
		}
		setAllTrips(allTrips);
		setTodayTrips(todTrips);
		setYesterdayTrips(yesTrips);
	};
	const numberWithCommas = (x) => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};
	// const valid = (current) => {
	//   return current.isAfter(moment(startDate));
	// };
	return (
		<main className="main_container">
			<div className="main_container_content">
				<Header title="Delivery Partner Admin" />
				<section className="main_section flex_row_top content_section tab_column">
					<section className="left_section pr_25 width_full">
						<section className="top_left grid_third mb_35">
							<TextCard
								cardLabel="Revenue"
								cardValue={`₦${
									(logistics &&
										logistics.revenue &&
										numberWithCommas(logistics.revenue)) ||
									"0"
								}`}
								withFooter
								cardFooter="Date shown: 25 nov `20 - 25 dec '20"
								loading={loading}
							/>

							<TextCard
								cardLabel="Trips"
								cardValue={
									(logistics && logistics.trips && logistics.trips.length) ||
									"-"
								}
								withFooter
								cardFooter="Date shown: 25 nov `20 - 25 dec '20"
								loading={loading}
							/>
							<TextCard
								cardLabel="Fleet"
								cardValue={
									(logistics && logistics.fleets && logistics.fleets.length) ||
									"-"
								}
								withLink
								link={
									<Link to="#/">
										<label className="gray_label bold cursor_pointer">
											Click to view
										</label>
									</Link>
								}
								loading={loading}
							/>
							<TextCard
								cardLabel="Average price per trip"
								cardValue={`₦${
									(logistics &&
										logistics.average_price_per_trip &&
										numberWithCommas(
											parseFloat(logistics.average_price_per_trip).toFixed(1)
										)) ||
									"0"
								}`}
								withFooter
								cardFooter="Date shown: 25 nov `20 - 25 dec '20"
								loading={loading}
							/>
							<TextCard
								cardLabel="Bike online"
								cardValue={bikesOnline && bikesOnline.length}
								withLink
								link={
									<p className="gray_label flex_row_center">
										{"Live "}
										<span className="green_text normal_53px bold ml_5"> •</span>
									</p>
								}
								loading={loading}
							/>
						</section>

						<section className="bottom_left_section">
							<div className="border_bottom_thin flex_row_between">
								<p className="bold_21px mb_15">Trip history</p>
								<Link to="#/">
									<p className="gray_label mb_15 bold">Show all</p>
								</Link>
							</div>

							<div className="border_bottom_thin flex_row_between py_15">
								<p className="bold_18_px">Today ({todayTrips.length})</p>
								<Link
									to="#/"
									onClick={() => setShowTodayTrips((prev) => !prev)}
								>
									<p className="gray_label mb_15 bold">
										{showTodayTrips ? "Collapse trips" : "Display trips"}
									</p>
								</Link>
							</div>

							<table className="width_full">
								<tbody>
									<p className="gray_label bold_21px uppercase mt_25 pb_15">
										{!loading && todayTrips.length === 0
											? "No trips were recorded for today"
											: null}
									</p>
									{/* Loader */}
									{loading ? (
										<tr>
											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_center">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>

											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>

											<td>
												<div className="flex_column_right">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
										</tr>
									) : null}
									{/* Loader End */}
									{showTodayTrips
										? todayTrips

												.sort(
													(a, b) =>
														new Date(b.created_at) - new Date(a.created_at)
												)
												.map((item, i) => {
													let status =
														item && item.completed
															? "Completed"
															: item && item.cancelled
															? "Cancelled"
															: item && !item.completed && !item.cancelled
															? "Pending"
															: "";
													return (
														<tr key={i + "item"}>
															<td>
																<div className="flex_column_left">
																	<label className="gray_label mb_4">
																		{item &&
																			item.created_at &&
																			moment(item.created_at).format("LT")}
																	</label>
																	<label className="gray_label mb_4">
																		{item &&
																			item.created_at &&
																			moment(item.created_at).format(
																				"DD MMM YYYY"
																			)}
																	</label>
																	<label className="gray_label">
																		#{item && item.order_code}
																	</label>
																</div>
															</td>
															<td>
																<div className="flex_column_left">
																	<div className="flex_row_align_center mb_10">
																		<span className="normal_53px green_text mr_10">
																			•
																		</span>
																		<p className="normal_15px">
																			From:{" "}
																			<span className="bold">CoKitchen</span>
																		</p>
																	</div>

																	<div className="flex_row_align_center">
																		<span className="normal_53px red_text mr_10">
																			•
																		</span>
																		<p className="normal_15px">
																			To:{" "}
																			<span className="bold">
																				{item &&
																					item.calculated_order &&
																					item.calculated_order
																						.address_details &&
																					item.calculated_order.address_details
																						.address_line}
																			</span>
																		</p>
																	</div>
																</div>
															</td>

															<td>
																<div className="flex_column_left">
																	<div className="flex_row_center mb_17">
																		<Clock
																			fill="#939393"
																			width="8px"
																			height="8px"
																			className="mr_15"
																		/>

																		<p
																			className={`normal_15px bold ${
																				status === "Completed"
																					? "green_text"
																					: status === "Cancelled"
																					? "red_text"
																					: "gray"
																			}`}
																		>
																			{status}
																		</p>
																	</div>

																	<div className="flex_row_align_center">
																		<User
																			fill="#939393"
																			width="9px"
																			height="9px"
																			className="mr_15"
																		/>

																		<p className="normal_15px">
																			{item &&
																				item.rider &&
																				item.rider.first_name +
																					" " +
																					item.rider.last_name +
																					" (Rider)"}
																		</p>
																	</div>
																</div>
															</td>

															<td>
																<div className="flex_column_right">
																	<span className="bold_21px green_text mb_17">
																		₦
																		{item?.calculated_order?.delivery_fee &&
																			numberWithCommas(
																				parseInt(
																					item.calculated_order.delivery_fee
																				)
																			)}
																	</span>

																	<div className="flex_row_right">
																		<Link
																			to={`trip_history/${
																				item && item.order_code
																			}`}
																		>
																			{" "}
																			<p className="mr_10 normal_15px black">
																				See full trip details
																			</p>
																		</Link>
																		<Link
																			to={`trip_history/${
																				item && item.order_code
																			}`}
																		>
																			<ChevronRight
																				fill="#000000"
																				width="11px"
																				height="11px"
																			/>
																		</Link>
																	</div>
																</div>
															</td>
														</tr>
													);
												})
										: null}
								</tbody>
							</table>

							<div className="border_bottom_thin flex_row_between py_15">
								<p className="bold_18_px">
									Yesterday ({yesterdayTrips.length})
								</p>

								<Link
									to="#/"
									onClick={() => setShowYesterdayTrips((prev) => !prev)}
								>
									<p className="gray_label mb_15 bold">
										{showYesterdayTrips ? "Collapse trips" : "Display trips"}
									</p>
								</Link>
							</div>
							<table className="width_full">
								<tbody>
									<p className="gray_label bold_21px uppercase mt_25 pb_15">
										{!loading && yesterdayTrips.length === 0
											? "No trips were recorded for yesterday"
											: null}
									</p>
									{/* Loader */}
									{loading ? (
										<tr>
											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_center">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>

											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>

											<td>
												<div className="flex_column_right">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
										</tr>
									) : null}
									{/* Loader End */}
									{showYesterdayTrips
										? yesterdayTrips
												.sort(
													(a, b) =>
														new Date(b.created_at) - new Date(a.created_at)
												)
												.map((item, i) => {
													let status =
														item && item.completed
															? "Completed"
															: item && item.cancelled
															? "Cancelled"
															: item && !item.completed && !item.cancelled
															? "Pending"
															: "";
													return (
														<tr key={i + "item"}>
															<td>
																<div className="flex_column_left">
																	<label className="gray_label mb_4">
																		{item &&
																			item.created_at &&
																			moment(item.created_at).format("LT")}
																	</label>
																	<label className="gray_label mb_4">
																		{item &&
																			item.created_at &&
																			moment(item.created_at).format(
																				"DD MMM YYYY"
																			)}
																	</label>
																	<label className="gray_label">
																		#{item && item.order_code}
																	</label>
																</div>
															</td>
															<td>
																<div className="flex_column_left">
																	<div className="flex_row_align_center mb_10">
																		<span className="normal_53px green_text mr_10">
																			•
																		</span>
																		<p className="normal_15px">
																			From:{" "}
																			<span className="bold">CoKitchen</span>
																		</p>
																	</div>

																	<div className="flex_row_align_center">
																		<span className="normal_53px red_text mr_10">
																			•
																		</span>
																		<p className="normal_15px">
																			To:{" "}
																			<span className="bold">
																				{item &&
																					item.calculated_order &&
																					item.calculated_order
																						.address_details &&
																					item.calculated_order.address_details
																						.address_line}
																			</span>
																		</p>
																	</div>
																</div>
															</td>

															<td>
																<div className="flex_column_left">
																	<div className="flex_row_center mb_17">
																		<Clock
																			fill="#939393"
																			width="8px"
																			height="8px"
																			className="mr_15"
																		/>

																		<p
																			className={`normal_15px bold ${
																				status === "Completed"
																					? "green_text"
																					: status === "Cancelled"
																					? "red_text"
																					: "gray"
																			}`}
																		>
																			{status}
																		</p>
																	</div>

																	<div className="flex_row_align_center">
																		<User
																			fill="#939393"
																			width="9px"
																			height="9px"
																			className="mr_15"
																		/>

																		<p className="normal_15px">
																			{item &&
																				item.rider &&
																				item.rider.first_name +
																					" " +
																					item.rider.last_name +
																					" (Rider)"}
																		</p>
																	</div>
																</div>
															</td>

															<td>
																<div className="flex_column_right">
																	<span className="bold_21px green_text mb_17">
																		₦
																		{item?.calculated_order?.delivery_fee &&
																			numberWithCommas(
																				parseInt(
																					item.calculated_order.delivery_fee
																				)
																			)}
																	</span>

																	<div className="flex_row_right">
																		<Link
																			to={`trip_history/${
																				item && item.order_code
																			}`}
																		>
																			{" "}
																			<p className="mr_10 normal_15px black">
																				See full trip details
																			</p>
																		</Link>
																		<Link
																			to={`trip_history/${
																				item && item.order_code
																			}`}
																		>
																			<ChevronRight
																				fill="#000000"
																				width="11px"
																				height="11px"
																			/>
																		</Link>
																	</div>
																</div>
															</td>
														</tr>
													);
												})
										: null}
								</tbody>
							</table>
							<div className="p_15_40 flex_row_center">
								<Button
									className="red_button_thick width_full"
									text={
										showAllTrips
											? `Hide full trip history  (${logistics?.trips?.length})`
											: `Show full trip history  (${logistics?.trips?.length})`
									}
									onClick={() => {
										setShowAllTrips(!showAllTrips);
									}}
								/>
							</div>
							{showAllTrips ? (
								<table className="width_full slideup">
									<tbody>
										<p className="gray_label bold_21px uppercase mt_25 pb_15">
											{!loading && logistics?.trips?.length === 0
												? "No trips has been recorded"
												: null}
										</p>
										{/* Loader */}
										{loading ? (
											<tr>
												<td>
													<div className="flex_column_left">
														<Loader
															type="ThreeDots"
															color="#ff000060"
															height={10}
															width={50}
														/>
													</div>
												</td>
												<td>
													<div className="flex_column_center">
														<Loader
															type="ThreeDots"
															color="#ff000060"
															height={10}
															width={50}
														/>
													</div>
												</td>

												<td>
													<div className="flex_column_left">
														<Loader
															type="ThreeDots"
															color="#ff000060"
															height={10}
															width={50}
														/>
													</div>
												</td>

												<td>
													<div className="flex_column_right">
														<Loader
															type="ThreeDots"
															color="#ff000060"
															height={10}
															width={50}
														/>
													</div>
												</td>
											</tr>
										) : null}
										{/* Loader End */}
										{logistics?.trips

											.sort(
												(a, b) =>
													new Date(b.created_at) - new Date(a.created_at)
											)
											.map((item, i) => {
												let status =
													item && item.completed
														? "Completed"
														: item && item.cancelled
														? "Cancelled"
														: item && !item.completed && !item.cancelled
														? "Pending"
														: "";
												return (
													<tr key={i + "item"}>
														<td>
															<div className="flex_column_left">
																<label className="gray_label mb_4">
																	{item &&
																		item.created_at &&
																		moment(item.created_at).format("LT")}
																</label>
																<label className="gray_label mb_4">
																	{item &&
																		item.created_at &&
																		moment(item.created_at).format(
																			"DD MMM YYYY"
																		)}
																</label>
																<label className="gray_label">
																	#{item && item.order_code}
																</label>
															</div>
														</td>
														<td>
															<div className="flex_column_left">
																<div className="flex_row_align_center mb_10">
																	<span className="normal_53px green_text mr_10">
																		•
																	</span>
																	<p className="normal_15px">
																		From:{" "}
																		<span className="bold">CoKitchen</span>
																	</p>
																</div>

																<div className="flex_row_align_center">
																	<span className="normal_53px red_text mr_10">
																		•
																	</span>
																	<p className="normal_15px">
																		To:{" "}
																		<span className="bold">
																			{item &&
																				item.calculated_order &&
																				item.calculated_order.address_details &&
																				item.calculated_order.address_details
																					.address_line}
																		</span>
																	</p>
																</div>
															</div>
														</td>

														<td>
															<div className="flex_column_left">
																<div className="flex_row_center mb_17">
																	<Clock
																		fill="#939393"
																		width="8px"
																		height="8px"
																		className="mr_15"
																	/>

																	<p
																		className={`normal_15px bold ${
																			status === "Completed"
																				? "green_text"
																				: status === "Cancelled"
																				? "red_text"
																				: "gray"
																		}`}
																	>
																		{status}
																	</p>
																</div>

																<div className="flex_row_align_center">
																	<User
																		fill="#939393"
																		width="9px"
																		height="9px"
																		className="mr_15"
																	/>

																	<p className="normal_15px">
																		{item &&
																			item.rider &&
																			item.rider.first_name +
																				" " +
																				item.rider.last_name +
																				" (Rider)"}
																	</p>
																</div>
															</div>
														</td>

														<td>
															<div className="flex_column_right">
																<span className="bold_21px green_text mb_17">
																	₦
																	{item?.calculated_order?.delivery_fee &&
																		numberWithCommas(
																			parseInt(
																				item.calculated_order.delivery_fee
																			)
																		)}
																</span>

																<div className="flex_row_right">
																	<Link
																		to={`trip_history/${
																			item && item.order_code
																		}`}
																	>
																		{" "}
																		<p className="mr_10 normal_15px black">
																			See full trip details
																		</p>
																	</Link>
																	<Link
																		to={`trip_history/${
																			item && item.order_code
																		}`}
																	>
																		<ChevronRight
																			fill="#000000"
																			width="11px"
																			height="11px"
																		/>
																	</Link>
																</div>
															</div>
														</td>
													</tr>
												);
											})}
									</tbody>
								</table>
							) : null}
						</section>
					</section>

					<section className="right_section px_10 second_width_desktop">
						<div className="flex_row_right mb_25">
							<a href="#/">
								<label className="gray_label bold cursor_pointer">
									Show all time data
								</label>
							</a>
						</div>

						<div
							className="button red_border borderless_dates flex_row_center mb_30"
							onClick={() => {
								if (showDateRange) {
									return;
								} else {
									setShowDateRange(true);
								}
							}}
						>
							{showDateRange && (
								<div
									className={showDateRangeFilters ? "" : "collapsed_date_range"}
								>
									<div className="flex_row_between">
										<button
											className="cursor_pointer mb_12 date_filter_button"
											onClick={() =>
												setShowDateRangeFilters(!showDateRangeFilters)
											}
										>
											{showDateRangeFilters
												? "Hide date filters"
												: " Show date filters"}
										</button>
										{filterCount > 1 ? (
											<button
												className="cursor_pointer mb_12 date_filter_button"
												onClick={() => setFilterCount(0)}
											>
												Clear date filter
											</button>
										) : null}
									</div>

									<DateRangePicker
										ranges={[selectionRange]}
										onChange={handleSelect}
										showMonthAndYearPickers={false}
									/>
								</div>
							)}
						</div>

						<div className="bold_21px border_bottom_thin width_full p_15">
							All-time fleet performance summary ({fleets.length})
						</div>
						{/* Summary item */}

						<p className="gray_label bold_21px uppercase mt_25 pb_15">
							{!loading && fleets.length === 0 ? "Fleets are empty" : null}
						</p>
						{fleets.map((item, i, arr) => {
							return (
								<div
									className={`summary_item flex_column p_15_15_20 ${
										arr.length - 1 === i ? "" : "border_bottom_thin"
									}`}
									key={item.name + i}
								>
									<div className="flex_row_between mb_5">
										<div className="flex_row_align_center">
											<span className="mr_15">
												<User fill="#939393" width="11px" height="11px" />
											</span>
											<p className="bold_18_px">
												{item.first_name + " " + item.last_name}
											</p>
										</div>
										<div className="flex_row_right">
											<p className="mr_10 normal_15px gray cursor_pointer">
												rider details
											</p>
											<ChevronRight fill="#939393" width="11px" height="11px" />
										</div>
									</div>

									<table>
										<tbody>
											<tr>
												<td className="normal_19px black text_right">
													{item.trips}
												</td>

												<td className="normal_19px black text_right">
													{item &&
														item.balance &&
														"₦" + numberWithCommas(item.balance)}
												</td>
												<td className="normal_19px black text_right">
													{item.hours}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							);
						})}
					</section>
				</section>
			</div>
		</main>
	);
};

export default withAlert()(HomePage);
